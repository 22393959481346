<template>
  <div class="Search">
    <v-container fluid>
      <PageTitle :title-text="$t('Consultas por número de economico')" />
      <v-row>
        <v-col md="6">
          <v-file-input
            v-model="fileToUpload"
            show-size
            counter
            label="File input"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="onFilePicked"
          />
        </v-col>
        <v-col md="4">
          <v-btn
            :disabled="!fileToUpload"
            :loading="uploadingFile"
            class="white--text"
            color="purple darken-2"
            @click="initUpload"
          >
            Subir archivo
          </v-btn>
          <v-dialog
            v-model="uploadingFile"
            hide-overlay
            persistent
            width="300"
          >
            <v-card
              color="primary"
              dark
            >
              <v-card-text>
                Please stand by
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                />
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="6"
        >
          <v-select
            v-model="fileToUpdateDBSelected"
            :items="files"
            label="Seleccione archivo a importar"
            dense
          />
        </v-col>
        <v-col md="4">
          <v-btn
            :disabled="!fileToUpdateDBSelected"
            elevation="6"
            @click="initImport"
          >
            Iniciar Importación
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-card
            elevation="8"
            outlined
            shaped
          >
            <v-card-text v-if="statusCurses">
              <div>Estado de importación</div>
              <p
                class="display-1 text--primary"
                :class="{color: statusCurses.statusInCurse ? 'red' : 'green'}"
              >
                {{ statusCurses.statusInCurse ? 'Importación en curso' : 'Ninguna' }}
              </p>
              <p>Colección: {{ statusCurses.name }}</p>
              <div class="text--primary">
                Ultima actualización: {{ moment(statusCurses.updatedAt).format('lll') }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                elevation="6"
                :loading="requestStatus"
                @click="getStatus"
              >
                Get Status
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <v-btn
            elevation="6"
            :loading="requestLogs"
            @click="getLogs"
          >
            Get Logs
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :items="logs"
            :headers="headers"
            :no-data-text="$t('commonTable.noDataText')"
            :footer-props="{ itemsPerPageText: $t('commonTable.perPageText') }"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatShowDate(item.createdAt) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue';
import moment from 'moment';
import dateFormatMixin from '@/mixins/dateFormat';
import importService from '../services/modules/importxlsx';

export default {
  name: 'Record',
  components: { PageTitle },
  mixins: [dateFormatMixin],
  data() {
    return {
      moment,
      requestStatus: false,
      requestLogs: false,
      uploadingFile: false,
      fileToUpdateDBSelected: '',
      amountUpload: 0,
      statusCurses: [],
      fileToUpload: null,
      logs: [],
      files: [],
      headers: [
        { text: 'createdAt', value: 'createdAt' },
        { text: 'status', value: 'status' },
        { text: 'collectionName', value: 'collectionName' },
        { text: 'event', value: 'event' },
        { text: 'message', value: 'message' },
        { text: 'sid', value: '_id' },
      ],
    };
  },
  mounted() {
    this.getStatus();
    this.getFilesxls();
  },
  methods: {
    onFilePicked(files) {
      this.fileToUpload = files;
    },
    async initUpload() {
      const resConfirm = await this.$confirm('Procederás a subir un archivo.<br>¿deseas continuar?');
      if (!this.fileToUpload || !resConfirm) {
        return;
      }

      this.uploadingFile = true;
      const file = this.fileToUpload;
      const formData = new FormData();
      formData.append('file', file);
      importService.uploadXlsx(formData)
          .then((response) => {
            this.uploadingFile = false;
            this.getFilesxls();
            // console.log('SUCCESS!!');
            this.fileToUpload = null;
            this.messageUpload = response.data.message;
          })
          .catch((error) => {
            this.uploadingFile = false;

            // console.log('FAILURE!!');
            document.getElementById('result').innerText = JSON.stringify(
                error,
                null,
                4,
            );
          });
    },
    async initImport() {
      const resConfirm = await this.$confirm('Procederás a importar una base de datos.<br>¿deseas continuar?');

      if (!resConfirm || !this.fileToUpdateDBSelected || this.fileToUpdateDBSelected.length === 0) {
        return;
      }
      // console.log(this.fileToUpdateDBSelected);
      this.getStatus();

      importService.updateFromFilename(this.fileToUpdateDBSelected)
          .then((response) => {
            this.fileToUpdateDBSelected = '';

            this.getStatus();
            // console.log('SUCCESS update status!!', response.data);
          })
          .catch((error) => {
            this.fileToUpdateDBSelected = '';

            this.getLogs();
            console.error('FAILURE!!', error);
          });
    },
    getLogs() {
      this.requestLogs = true;
      importService.getLogs()
          .then((response) => {
            this.logs = response.data;
            this.requestLogs = false;
          })
          .catch((error) => {
            this.requestLogs = false;
            console.error('FAILURE!!', error);
          });
    },
    getStatus() {
      this.requestStatus = true;
      importService.getStatus()
          .then((response) => {
            this.statusCurses = response.data[0] || {
              statusInCurse: false,
              name: 'Ninguno',
              updatedAt: new Date(),
            };
            this.requestStatus = false;
          })
          .catch((error) => {
            this.requestStatus = false;
            console.error('FAILURE!!', error);
          });
    },
    getFilesxls() {
      importService.getListFiles()
          .then((response) => {
            this.files = response.data;
          })
          .catch(function(error) {
            console.error('FAILURE!!', error);
          });
    },
  },
};
</script>
<style>
.v-sheet.v-card.v-sheet--shaped {
    border-radius: 0;
}
</style>
