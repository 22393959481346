import { apiClient } from '../index';

/**
 * [logs description]
 * @return {[type]}          [description]
 */
function getLogs() {
  return apiClient.get('/synchronize/logs');
}

/**
 * [status description]
 * @return {[type]}          [description]
 */
function getStatus() {
  return apiClient.get('/synchronize/status');
}

/**
 * [status description]
 * @return {[type]}          [description]
 */
function getListFiles() {
  return apiClient.get('/synchronize/xlsx/files');
}

/**
 * [status description]
 * @param {String} filename sss
 * @returns  {[type]} [description]
 */
function updateFromFilename(filename) {
  return apiClient.post('/synchronize/synchronizeFromFile', { filename });
}

/**
 * [status description]
 * @param {Object} formdata sss
 * @returns  {[type]} [description]
 */
function uploadXlsx(formdata) {
  return apiClient.put('/synchronize/xlsx/upload', formdata);
}

export default {
  getLogs, getStatus, getListFiles, updateFromFilename, uploadXlsx,
};
